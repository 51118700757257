import actions from "./actions";

const {FETCH_USER_BUSINESS_PACKAGE_REQUESTS} = actions;
const initState = {
    userBusinessPackageRequests: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const UserBusinessPackageRequestsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USER_BUSINESS_PACKAGE_REQUESTS:
            return {
                ...state,
                userBusinessPackageRequests: data
            }
        default:
            return state;
    }
}

export default UserBusinessPackageRequestsReducer;
