import actions from './actions';
import {RequestService as req} from '../../services/requestService';
import { Constants } from '../../config/constants';

const {
    LoadingStart, LoadingEnd,
    fetchRolesAction, fetchLocationsAction, fetchCitiesAction, fetchCountriesAction,
    fetchCategoriesAction, fetchSubCategoriesAction, fetchChildCategoriesAction, fetchBusinessPackageAction
} = actions;

const loadingStart = () => async dispatch => dispatch(LoadingStart());
const loadingEnd = () => async dispatch => dispatch(LoadingEnd());

const fetchRoles = () => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'roles', auth: 'bearer'}, (cb) => dispatch(fetchRolesAction(cb)))
    };
};

const fetchCountries = () => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'countries', auth: 'bearer'}, (cb) => dispatch(fetchCountriesAction(cb)))
    };
};

const fetchCities = (queries) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'cities', queries: queries, auth: 'bearer'}, (cb) => dispatch(fetchCitiesAction(cb)))
    };
};

const fetchLocations = (queries) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'locations', queries: queries, auth: 'bearer'}, (cb) => dispatch(fetchLocationsAction(cb)))
    };
};

const fetchCategories = (queries) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'categories', queries: queries, auth: 'bearer'}, (cb) => dispatch(fetchCategoriesAction(cb)))
    };
};

const fetchSubCategories = (queries) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'sub-categories', queries, auth: 'bearer'}, (cb) => dispatch(fetchSubCategoriesAction(cb)))
    };
};

const fetchChildCategories = (queries) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.UTILITIES + 'child-categories', queries, auth: 'bearer'}, (cb) => dispatch(fetchChildCategoriesAction(cb)))
    };
};

export const fetchUtilBusinessPackages = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.UTILITIES + 'business-packages',
            queries, auth: 'bearer'
        }, (cb) => dispatch(fetchBusinessPackageAction(cb)))
    };
};

export {
    loadingStart, loadingEnd,
    fetchRoles, fetchCountries, fetchCities, fetchLocations,
    fetchCategories, fetchSubCategories, fetchChildCategories
};
