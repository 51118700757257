import actions from "./actions";

const {FETCH_USERS, FETCH_SINGLE_USER, RESET_SINGLE_USER} = actions;
const initState = {
    users: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    user: {
        fullName: null,
        email: null,
        gender: null,
        number: null,
        about: null,
        status: null,
    }
};

const UsersReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USERS:
            return {
                ...state,
                users: data
            }
        case FETCH_SINGLE_USER:
            return {
                ...state,
                user: data
            }
        case RESET_SINGLE_USER:
            return {
                ...state,
                user: data
            }
        default:
            return state;
    }
}

export default UsersReducer;
