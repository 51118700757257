const actions = {
    FETCH_REPORTED_MESSAGES  : 'FETCH_REPORTED_MESSAGES',

    fetchReportedMessagesAction: (data) => {
        return {
            type: actions.FETCH_REPORTED_MESSAGES,
            data: data
        };
    },
}

export default actions;
