const actions = {
    FETCH_PAYMENTS   : 'FETCH_PAYMENTS',

    fetchPaymentsAction: (data) => {
        return {
            type: actions.FETCH_PAYMENTS,
            data: data
        };
    },
}

export default actions;
