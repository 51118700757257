const actions = {
    FETCH_USERS   : 'FETCH_USERS',
    FETCH_SINGLE_USER   : 'FETCH_SINGLE_USER',
    RESET_SINGLE_USER   : 'RESET_SINGLE_USER',

    fetchUsersAction: (data) => {
        return {
            type: actions.FETCH_USERS,
            data: data
        };
    },

    fetchSingleUserAction: (data) => {
        return {
            type: actions.FETCH_SINGLE_USER,
            data: data
        };
    },

    resetSingleUserActionForm: (data) => {
        return {
            type: actions.RESET_SINGLE_USER,
            data: {
                fullName: null,
                email: null,
                gender: null,
                number: null,
                about: null,
                status: null,
            }
        };
    },
}

export default actions;
