import actions from "./actions";

const {FETCH_REPORTED_MESSAGES} = actions;
const initState = {
    reportedMessages: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
};

const ReportedMessagesReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_REPORTED_MESSAGES:
            return {
                ...state,
                reportedMessages: {data: data}
            }
        default:
            return state;
    }
}

export default ReportedMessagesReducer;
