import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Scope } from "../services/scopeService";

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');

    return (
        <Menu
            mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
            theme={darkMode && 'dark'}
            // // eslint-disable-next-line no-nested-ternary
            defaultSelectedKeys={
                !topMenu
                    ? [
                        `${
                            mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                        }`,
                    ]
                    : []
            }
            defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
            overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        >
            <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="dashboard">
                <NavLink to={`${path}/dashboard`}>
                    Dashboard
                </NavLink>
            </Menu.Item>
            {/* <SubMenu key="user-management" icon={!topMenu && <FeatherIcon icon="user-check" />} title="User Management">
                {Scope.checkScopes(['um_users_index']) && (
                    <Menu.Item key="um-users">
                        <NavLink onClick={toggleCollapsed} to={`${path}/user-management/users`}>
                            Users
                        </NavLink>
                    </Menu.Item>
                )}
            </SubMenu> */}
            <SubMenu key="web-setup" icon={!topMenu && <FeatherIcon icon="sliders" />} title="Web Setup">
                {Scope.checkScopes(['ws_subscribe_packages_index']) && (
                    <Menu.Item key="subscribe-packages">
                        <NavLink onClick={toggleCollapsed} to={`${path}/web-setup/subscribe-packages`}>
                            Subscribe Packages
                        </NavLink>
                    </Menu.Item>
                )}
            </SubMenu>
            <SubMenu key="workspace" icon={!topMenu && <FeatherIcon icon="briefcase" />} title="Workspace">
                {Scope.checkScopes(['workspace_chat-group_index']) && (
                    <Menu.Item key="chat-group">
                        <NavLink onClick={toggleCollapsed} to={`${path}/workspace/conversation-groups`}>
                            Conversation Groups
                        </NavLink>
                    </Menu.Item>
                )}
                {Scope.checkScopes(['workspace_chat-group_index']) && (
                    <Menu.Item key="reported-messages">
                        <NavLink onClick={toggleCollapsed} to={`${path}/workspace/reported-messages`}>
                            Reported Messages
                        </NavLink>
                    </Menu.Item>
                )}
            </SubMenu>

            <SubMenu key="clients" icon={!topMenu && <FeatherIcon icon="users" />} title="Clients">
                {Scope.checkScopes(['clients_users_index']) && (
                    <Menu.Item key="users">
                        <NavLink onClick={toggleCollapsed} to={`${path}/clients/users`}>
                            Users
                        </NavLink>
                    </Menu.Item>
                )}
            </SubMenu>
        </Menu>
    );
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
};

export default MenuItems;
