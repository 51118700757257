import actions from "./actions";

const {FETCH_SUBSCRIBE_PACKAGES, FETCH_SUBSCRIBE_PACKAGE, RESET_SUBSCRIBE_PACKAGES} = actions;
const initState = {
    subscribePackages: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    subscribePackage: {
        _id: null,
        type: null,
        price: null,
        currency: null,
        status: null,
    }
};

const SubscribePackagesReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SUBSCRIBE_PACKAGES:
            return {
                ...state,
                subscribePackages: data
            }
        case RESET_SUBSCRIBE_PACKAGES:
            return {
                ...state,
                subscribePackage: initState.businessPackage,
            };
        case FETCH_SUBSCRIBE_PACKAGE:
            return {
                ...state,
                subscribePackage: data,
            };
        default:
            return state;
    }
}

export default SubscribePackagesReducer;
