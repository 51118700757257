import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DashboardRoutes from './dashboard';
import UserManagementRoutes from './userManagement';
import WebSetupRoutes from './webSetup';
import ClientsRoutes from './clients'
import WorkspaceRoutes from './workspace'

import withAdminLayout from '../../layout/withAdminLayout';

const Settings = lazy(() => import('../../container/profile/settings/Settings'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));

const Admin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <Spin />
                    </div>
                }
            >
                <Route path={path} component={DashboardRoutes} />
                <Route path={`${path}/user-management`} component={UserManagementRoutes} />
                <Route path={`${path}/web-setup`} component={WebSetupRoutes} />
                <Route path={`${path}/clients`} component={ClientsRoutes} />
                <Route path={`${path}/workspace`} component={WorkspaceRoutes} />

                <Route path={`${path}/profile/settings`} component={Settings} />
                <Route path={`${path}/profile/myProfile`} component={Myprofile} />
            </Suspense>
        </Switch>
    );
};

export default withAdminLayout(Admin);
