const actions = {
    FETCH_GROUPS  : 'FETCH_GROUPS',
    FETCH_GROUP   : 'FETCH_GROUP',
    RESET_GROUPS   : 'RESET_GROUPS',

    fetchGroupsAction: (data) => {
        return {
            type: actions.FETCH_GROUPS,
            data: data
        };
    },
    resetGroupFormAction: () => {
        return {
            type: actions.RESET_GROUPS,
            data: null
        };
    },
    fetchGroupInfoAction: (data) => {
        return {
            type: actions.FETCH_GROUP,
            data: data
        };
    },
}

export default actions;
