const actions = {
    FETCH_ENROLLMENTS   : 'FETCH_ENROLLMENTS',

    fetchEnrollmentsAction: (data) => {
        return {
            type: actions.FETCH_ENROLLMENTS,
            data: data
        };
    },
}

export default actions;
