import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Groups = lazy(() => import('../../container/workspace/conversation-groups/index'));
const Payments = lazy(() => import('../../container/workspace/payments/index'));
const ReportedMessages = lazy(() => import('../../container/workspace/reported-messages/index'));

const ClientsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/conversation-groups`} component={Groups}/>
            <Route exact path={`${path}/reported-messages`} component={ReportedMessages}/>
            <Route exact path={`${path}/payments`} component={Payments}/>
        </Switch>
    );
};

export default ClientsRoutes;
