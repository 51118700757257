const actions = {
    FETCH_QUIZZES   : 'FETCH_QUIZZES',

    fetchQuizzesAction: (data) => {
        return {
            type: actions.FETCH_QUIZZES,
            data: data
        };
    },
}

export default actions;
