import actions from "./actions";

const {FETCH_ENROLLMENTS} = actions;
const initState = {
    enrollments: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const EnrollmentsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ENROLLMENTS:
            return {
                ...state,
                enrollments: data
            }
        default:
            return state;
    }
}

export default EnrollmentsReducer;
