import actions from './actions';

const {
    LOADING_START, LOADING_END,
    FETCH_UTILITIES_ROLES, FETCH_UTILITIES_LOCATIONS, FETCH_UTILITIES_CITIES, FETCH_UTILITIES_COUNTRIES, FETCH_UTILITIES_CATEGORIES,
    FETCH_UTILITIES_BUSINESS_PACKAGES, FETCH_UTILITIES_SUB_CATEGORIES, FETCH_UTILITIES_CHILD_CATEGORIES,
} = actions;

const initState = {
    loading: false,
    roles: [],
    countries: [],
    cities: [],
    locations: [],
    categories: [],
    subCategories: [],
    childCategories: [],
    businessPackages: [],
};

const UtilitiesReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case LOADING_START:
            return {
                ...state,
                loading: true,
            };
        case LOADING_END:
            return {
                ...state,
                loading: false,
            };
        case FETCH_UTILITIES_ROLES:
            return {
                ...state,
                roles: data,
            };
        case FETCH_UTILITIES_COUNTRIES:
            return {
                ...state,
                countries: data,
            };
        case FETCH_UTILITIES_CITIES:
            return {
                ...state,
                cities: data,
            };
        case FETCH_UTILITIES_LOCATIONS:
            return {
                ...state,
                locations: data,
            };
        case FETCH_UTILITIES_CATEGORIES:
            return {
                ...state,
                categories: data,
            };
        case FETCH_UTILITIES_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: data,
            };
        case FETCH_UTILITIES_CHILD_CATEGORIES:
            return {
                ...state,
                childCategories: data,
            };
        case FETCH_UTILITIES_BUSINESS_PACKAGES:
            return {
                ...state,
                businessPackages: data,
            };
        default:
            return state;
    }
};
export default UtilitiesReducer;
