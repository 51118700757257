const actions = {
    FETCH_USER_FREELANCER_SERVICE_REQUESTS   : 'FETCH_USER_FREELANCER_SERVICE_REQUESTS',

    fetchUserFreelancerServiceRequestsAction: (data) => {
        return {
            type: actions.FETCH_USER_FREELANCER_SERVICE_REQUESTS,
            data: data
        };
    },
}

export default actions;
