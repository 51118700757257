import actions from "./actions";

const {FETCH_GROUP, FETCH_GROUPS, RESET_GROUP} = actions;
const initState = {
    groups: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    group: {
        name: null,
        status: null,
        subscription: {isValid: false, expiredDate: null}
    }
};

const GroupsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_GROUPS:
            return {
                ...state,
                groups: {data: data}
            }
        case RESET_GROUP:
            return {
                ...state,
                course: initState.group,
            };
        case FETCH_GROUP:
            return {
                ...state,
                group: data,
            };
        default:
            return state;
    }
}

export default GroupsReducer;
