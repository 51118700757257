import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';


const SubscribePackages = lazy(() => import('../../container/web-setup/subscribePackages/index'));

const UserManagementRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/subscribe-packages`} component={SubscribePackages}/>
        </Switch>
    );
};

export default UserManagementRoutes;
