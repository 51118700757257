const actions = {
    FETCH_INSTRUCTORS   : 'FETCH_INSTRUCTORS',
    FETCH_INSTRUCTOR   : 'FETCH_INSTRUCTOR',
    RESET_INSTRUCTOR   : 'RESET_INSTRUCTOR',

    fetchInstructorsAction: (data) => {
        return {
            type: actions.FETCH_INSTRUCTORS,
            data: data
        };
    },
    resetInstructorFormAction: () => {
        return {
            type: actions.RESET_INSTRUCTOR,
            data: null
        };
    },
    fetchInstructorAction: (data) => {
        return {
            type: actions.FETCH_INSTRUCTOR,
            data: data
        };
    },
}

export default actions;
