require('dotenv').config()
export class Constants {
    static BASE_ENDPOINT    = process.env.REACT_APP_BASE_ENDPOINT;
    // static BASE_ENDPOINT    = 'http://localhost:3031/api/';
    static CLIENT_ID = 'test-client';
    static CLIENT_SECRET = 'test-client';
    // static BASE_MEDIA_URL    = 'http://localhost:3333/';
    static BASE_MEDIA_URL = process.env.REACT_APP_BASE_ENDPOINT;

    static STORAGE_ACCESS_TOKEN = 'accessToken';
    static STORAGE_REFRESH_TOKEN = 'refreshToken';
    static STORAGE_USER_INFO = 'userInfo';
    static STORAGE_USER_SCOPES = 'userScopes';
    static STORAGE_USER_LOGGED_IN = 'userLoggedIn';

    static S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
    static S3_DIR_NAME = process.env.REACT_APP_S3_DIR_NAME;
    static S3_REGION = process.env.REACT_APP_S3_REGION;
    static S3_ACCESS_KEY_ID = process.env.REACT_APP_S3_ACCESS_KEY_ID;
    static S3_ACCESS_KEY_SECRET = process.env.REACT_APP_S3_ACCESS_KEY_SECRET;

    static S3_FILE_NAME = (key) => `${+ new Date()}-${key.replace(/[ ,.]/g, "-")}`;

    static AUTH = this.BASE_ENDPOINT + 'admin/auth/';
    static UTILITIES = this.BASE_ENDPOINT + 'admin/utilities/';

    static USER_MANAGEMENT = this.BASE_ENDPOINT + 'admin/user-management/';
    static WEB_SETUP = this.BASE_ENDPOINT + 'admin/web-setup/';
    static CLIENTS = this.BASE_ENDPOINT + 'admin/clients/';
    static WORKSPACE = this.BASE_ENDPOINT + 'admin/workspace/';
}
