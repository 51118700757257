const actions = {
    FETCH_SUBSCRIBE_PACKAGES  : 'FETCH_SUBSCRIBE_PACKAGES',
    FETCH_SUBSCRIBE_PACKAGE   : 'FETCH_SUBSCRIBE_PACKAGE',
    RESET_SUBSCRIBE_PACKAGES  : 'RESET_SUBSCRIBE_PACKAGES',

    fetchSubscribePackagesAction: (data) => {
        return {
            type: actions.FETCH_SUBSCRIBE_PACKAGES,
            data: data
        };
    },
    resetSubscribePackageFormAction: () => {
        return {
            type: actions.RESET_SUBSCRIBE_PACKAGES,
            data: {
                _id: null,
                type: null,
                price: null,
                currency: null,
                status: null,
            }
        };
    },
    fetchSubscribePackageAction: (data) => {
        return {
            type: actions.FETCH_SUBSCRIBE_PACKAGE,
            data: data
        };
    },
}

export default actions;
