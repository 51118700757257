import actions from "./actions";

const {FETCH_INSTRUCTOR, FETCH_INSTRUCTORS, RESET_INSTRUCTOR} = actions;
const initState = {
    instructors: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    },
    instructor: {_id: null, code: null, name: null, status: null, latitude: null, longitude: null}
};

const InstructorsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_INSTRUCTORS:
            return {
                ...state,
                instructors: data
            }
        case RESET_INSTRUCTOR:
            return {
                ...state,
                instructor: initState.instructor,
            };
        case FETCH_INSTRUCTOR:
            return {
                ...state,
                instructor: data,
            };
        default:
            return state;
    }
}

export default InstructorsReducer;
