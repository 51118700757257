import { combineReducers } from 'redux';

import authReducer from './authentication/reducers';
import utilitiesReducer from './utilities/reducers';

import departmentReducer from './userManagement/departments/reducers';
import roleReducer from './userManagement/roles/reducers';
import rolesPermissionsReducer from './userManagement/rolesPermissions/reducers';
import teamReducer from './userManagement/teams/reducers';
import userReducer from './userManagement/users/reducers';


import SubscribePackagesReducer from './webSetup/subscribePackages/reducers';

import EnrollmentsReducer from './clients/enrollments/reducers';
import instructorsReducer from './clients/instructors/reducers';
import PartnersReducer from './clients/partners/reducers';
import QuizzesReducer from './clients/quizzes/reducers';
import UserBusinessPackageRequestsReducer from './clients/userBusinessPackageRequests/reducers';
import UserFreelancerServiceRequestsReducer from './clients/userFreelancerServiceRequests/reducers';
import UserPackagesReducer from './clients/userPackages/reducers';
import usersReducer from './clients/users/reducers';

import groupsReducer from './workspace/groups/reducers';
import paymentsReducer from './workspace/payments/reducers';
import reportedMessageReducer from './workspace/reported-messages/reducers';

import chartContentReducer from './chartContent/reducers';
import Profile from './profile/reducers';
import ChangeLayoutMode from './themeLayout/reducers';

const rootReducers = combineReducers({
    auth: authReducer,
    utilities: utilitiesReducer,

    umRoles: roleReducer,
    umRolesPermissions: rolesPermissionsReducer,
    umUsers: userReducer,
    umDepartments: departmentReducer,
    umTeams: teamReducer,

    wsSubscribePackages: SubscribePackagesReducer,

    clientsInstructors: instructorsReducer,
    clientsUsers: usersReducer,
    clientsEnrollments: EnrollmentsReducer,
    clientsUserPackages: UserPackagesReducer,
    clientsUserFreelancerServiceRequests: UserFreelancerServiceRequestsReducer,
    clientsUserBusinessPackageRequests: UserBusinessPackageRequestsReducer,
    clientsQuizzes: QuizzesReducer,
    clientsPartners: PartnersReducer,

    workspaceGroups: groupsReducer,
    workspacePayments: paymentsReducer,
    workspaceReportedMessages: reportedMessageReducer,

    ChangeLayoutMode,
    chartContent: chartContentReducer,
    Profile,
});

export default rootReducers;
