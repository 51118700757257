import actions from "./actions";

const {FETCH_USER_FREELANCER_SERVICE_REQUESTS} = actions;
const initState = {
    userFreelancerServiceRequests: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const UserFreelancerServiceRequestsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USER_FREELANCER_SERVICE_REQUESTS:
            return {
                ...state,
                userFreelancerServiceRequests: data
            }
        default:
            return state;
    }
}

export default UserFreelancerServiceRequestsReducer;
