import actions from "./actions";

const {FETCH_USER_PACKAGES} = actions;
const initState = {
    userPackages: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const UserPackagesReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USER_PACKAGES:
            return {
                ...state,
                userPackages: data
            }
        default:
            return state;
    }
}

export default UserPackagesReducer;
