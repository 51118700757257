const actions = {
    FETCH_USER_PACKAGES   : 'FETCH_USER_PACKAGES',

    fetchUserPackagesAction: (data) => {
        return {
            type: actions.FETCH_USER_PACKAGES,
            data: data
        };
    },
}

export default actions;
