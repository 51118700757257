import actions from "./actions";

const {FETCH_QUIZZES} = actions;
const initState = {
    quizzes: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const QuizzesReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_QUIZZES:
            return {
                ...state,
                quizzes: data
            }
        default:
            return state;
    }
}

export default QuizzesReducer;
