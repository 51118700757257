import actions from './actions';

const { FETCH_ROLES_PERMISSIONS, RESET_ROLES_PERMISSIONS } = actions;
const initState = { rolesPermissions: [] };

const RolePermissionReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_ROLES_PERMISSIONS:
            return {
                ...state,
                rolesPermissions: data,
            };
        case RESET_ROLES_PERMISSIONS:
            return {
                ...state,
                rolesPermissions: initState.rolesPermissions,
            };
        default:
            return state;
    }
};
export default RolePermissionReducer;
