const actions = {
    LOADING_START       : 'LOADING_START',
    LOADING_END         : 'LOADING_END',
    FETCH_UTILITIES_ROLES       : 'FETCH_UTILITIES_ROLES',
    FETCH_UTILITIES_COUNTRIES   : 'FETCH_UTILITIES_COUNTRIES',
    FETCH_UTILITIES_CITIES      : 'FETCH_UTILITIES_CITIES',
    FETCH_UTILITIES_LOCATIONS   : 'FETCH_UTILITIES_LOCATIONS',
    FETCH_UTILITIES_CATEGORIES  : 'FETCH_UTILITIES_CATEGORIES',
    FETCH_UTILITIES_BUSINESS_PACKAGES  : 'FETCH_UTILITIES_BUSINESS_PACKAGES',
    FETCH_UTILITIES_SUB_CATEGORIES      : 'FETCH_UTILITIES_SUB_CATEGORIES',
    FETCH_UTILITIES_CHILD_CATEGORIES    : 'FETCH_UTILITIES_CHILD_CATEGORIES',

    LoadingStart: () => {
        return {
            type: actions.LOADING_START,
        };
    },
    LoadingEnd: data => {
        return {
            type: actions.LOADING_END
        };
    },
    fetchRolesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ROLES,
            data: data
        };
    },
    fetchCountriesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_COUNTRIES,
            data: data
        }
    },
    fetchCitiesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CITIES,
            data: data
        }
    },
    fetchLocationsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_LOCATIONS,
            data: data
        }
    },
    fetchCategoriesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CATEGORIES,
            data: data
        }
    },
    fetchBusinessPackageAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_BUSINESS_PACKAGES,
            data: data
        }
    },
    fetchSubCategoriesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SUB_CATEGORIES,
            data: data
        }
    },
    fetchChildCategoriesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CHILD_CATEGORIES,
            data: data
        }
    },
};

export default actions;
