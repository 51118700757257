const actions = {
    FETCH_USER_BUSINESS_PACKAGE_REQUESTS   : 'FETCH_USER_BUSINESS_PACKAGE_REQUESTS',

    fetchUserBusinessPackageRequestsAction: (data) => {
        return {
            type: actions.FETCH_USER_BUSINESS_PACKAGE_REQUESTS,
            data: data
        };
    },
}

export default actions;
